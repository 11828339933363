import { i18n } from '@/i18n'

export default () => (
  {
    serviceId: [{ required: true, message: i18n.t('required'), trigger: ['blur', 'change'] }],
    addOnName: [{ required: true, message: i18n.t('required'), trigger: ['blur', 'change'] }],
    addOnBillRecurrence: [{ required: true, message: i18n.t('required'), trigger: ['blur', 'change'] }],
    addOnPrice: [{ required: true, message: i18n.t('required'), trigger: ['blur', 'change'] }],
  }
)
