export default () => ({
  id: 0,
  serviceId: '',
  planId: '',
  addOnName: '',
  addOnBillRecurrence: '',
  addOnDescription: '',
  addOnIcon: '',
  addOnPrice: '',
  addOnTaxPercentIncrease: '',
  addOnTaxPercentIncluded: '',
  addOnIsMandatory: false,
  addOnEnabled: true,
})
